.home {
  display: flex;
  justify-content: center;
  /* margin-left: auto;
  margin-right: auto; */
  /* max-width: 1500px; */
  /* background: lightgray; */
}
.home__image {
  margin-left: 0px;
  margin-right: 0px;
  width: 100%;
  z-index: -1;
  opacity: 0.5;
  margin-bottom: -575px;
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}

.home__image1 {
  margin-left: 0px;
  margin-right: 0px;
  width: 100%;
  z-index: 0;
  opacity: 1;
  border: radius 25%;
}

.home__row {
  z-index: 1;
  display: flex;
  flex-direction: column;
  width: 80%;
  padding: 50px;
  margin: 100px;
  margin-left: 10px;
  margin-right: 10px;

  /* margin-top: -200px; */
  background-color: #fff;
  color: #000;
  /* font-weight: normal; */
}

.home__content {
  display: flex;
  padding-left: auto;
}

#boxes {
  margin-top: 20px;
}
#boxes .box {
  float: right;
  width: 30%;
  padding: 10px;
}

#boxes .box img {
  width: 90px;
}

.animate_p {
}
