.container {
  display: grid;
  align-items: center;
  justify-items: center;
  padding: 1em;
}

h1,
h2,
h3 {
  margin-top: 0;
  margin-bottom: 0.25em;
  margin-left: 0.25em;
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  color: yellow;
  width: 100%;
  padding: 11px 15px 11px 0;
}

.stripe-button-el span {
  background: #5e366a !important;
  background-image: none !important;
  background-color: #5e366a !important;
}

button {
  display: block;
  margin: 20px 0 0 0;
}

.donate {
  margin-left: 25%;
  margin-top: 5%;
  margin-right: 25%;
}

.amount {
  display: flex;
  flex-direction: row;
}
.amount__button > button {
  margin: 5px;
  margin-bottom: 15px;
  color: black;
}
form input {
  margin: 5px;
}
