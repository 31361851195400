.header {
  height: 60px;
  display: flex;
  align-items: center;
  background-color: #131921;
  position: sticky;
  top: 0;
  z-index: 100;
}

.header__logo {
  display: flex;
  flex-direction: column;
  width: 100px;
  object-fit: contain;
  margin: 0 20px;
  margin-top: 18px;
}
.header__logo__text {
  color: #3ba0e0;
  font-weight: 500;
}
.header__abbr {
  color: darkyellow;
  font-weight: 300;
}

.header__nav {
  display: flex;
  justify-content: space-around;
  margin-left: auto;
}

.nav-item {
  padding: 15px;
  font-weight: 500;
  font-size: larger;
}

.nav-item :hover {
  font-weight: bolder;
  /* background-color: rgba(54, 129, 175, 0.2); */
  transform: translateY(-2px);
}
.header__option {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
  color: white;
}

.social-container {
  padding: 0px 50px;
}

a.social {
  padding: 0px 25px;
  /* margin: 0 1rem 1rem 3rem; */
  transition: transform 250ms;
  display: inline-block;
}
a.social:hover {
  transform: translateY(2px);
}

a.youtube {
  color: #eb3223;
}

a.facebook {
  color: #4968ad;
}

a.twitter {
  color: #49a1eb;
}

a.instagram {
  color: red;
}
a.telegram {
  color: #49a1eb;
}

.logo__text {
  display: flex;
  flex-direction: column;
  padding: -20px;
}
