/* Features */

.featureBox {
  margin-top: 100px;
  float: left;
  width: 33.3%;
  padding: 30px;
  text-align: left;
}

.featureBox i {
  margin-bottom: 10px;
}
