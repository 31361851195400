.dashboard {
  /* float: left; */
  /* margin: 100px 100px 100px 150px; */
  /* margin-left: 200px; */
  width: 200px;
  height: 200px;
  font-weight: 900;
  font-size: large;
  background: gradient;
  color: darkblue;
  border-radius: 50%;
  border: 5px solid #40a8e9;
  margin: auto auto;
  margin-top: 50px;
  margin-bottom: 50px;
  padding-right: 25px;
  padding-left: 10px;
  padding-top: 50px;
  padding-bottom: 110px;

  align-items: center;
  display: inline-block;
}
