.bg-image {
  /* The image used */
  /* background-image: url("photographer.jpg"); */

  /* Add the blur effect */
  filter: blur(8px);
  -webkit-filter: blur(8px);

  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

/* Position text in the middle of the page/image */
.bg-text {
  /* background-color: rgb(0, 0, 0); /* Fallback color */
  /*background-color: rgba(0, 0, 0, 0.4); Black w/opacity/see-through */

  background-color: #343A40;
  color: white;
  font-weight: bold;
  /* border: 3px solid #f1f1f1; */
  position: relative;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  width: 100%;
  padding: 80px;
  padding-bottom: 300px;
  text-align: center;
  margin-top: 300px;
  opacity: 1;
}
