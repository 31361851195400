.donate {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid white;
  padding: 50px;

  margin-top: 5%;
}

/* input {
  display: flex;
  flex-direction: column;
  padding: 50px;
} */
